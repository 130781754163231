@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Whyte";
  src: url("https://devstaticasset.azureedge.net/font/ABCWhyte-Regular.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
}
.text {
  font-family: 'Quicksand', sans-serif;
}

.text-2 {
  font-family: "Whyte", sans-serif;
}

.text-med {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}
.bold {
  font-family: 'Bungee', monospace;
  line-height: 5.2rem;
}

.bold-fira {
  font-family: 'Fira Sans';
  font-weight: bold;
}

body {
  background-color: #b2aea6;
}
.container {
  -webkit-animation-duration: 0.45s;
          animation-duration: 0.45s;
  -webkit-animation-name: FadeInDown;
          animation-name: FadeInDown;
}

@-webkit-keyframes FadeInDown{
  from {
    opacity: 0; 
    transform: translateY(2rem)
  }

  to {
    opacity: 1; transform: translateY(0rem)
  }
}

@keyframes FadeInDown{
  from {
    opacity: 0; 
    transform: translateY(2rem)
  }

  to {
    opacity: 1; transform: translateY(0rem)
  }
}
form {
  margin: 5rem auto 0;
  width: 24rem;
}
form div {
  width: inherit;
  margin-bottom: 1rem;
}
form label {
  display: block;
  color: #fff;
}
form input {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  width: inherit;
  height: 2rem;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
form input[type="submit"] {
  height: 2.5rem;
  font-size: 1rem;
  color: #fff;
  background: #0971f1;
  cursor: pointer;
}
form p {
  margin: 0;
  color: #f90;
}
.head{
  margin-top: -10rem;
}
.heading{

vertical-align: text-top;

}
.heleft{
  display:inline
}
.left{
  display:list-item;
  text-align: center;
}
.boox{
  margin-top: 1rem;
  display:flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.booxlang{
  display:flex;
  justify-content: center
  ;}
.input{
  display: flex;
  justify-content: flex-start;

}
.pages {
  color: #fefaf2;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 10%;
}

.navbar {
  background-color: #3d3d3d;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  text-align: left;
  float: left;
  max-width: 1500px;
}
.img-fluid{
  width: 100%;
  height: 100%;
  text-align: left;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  color: #fefaf2;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}
.logo-image{ 
  display: flex;
  justify-content: space-between;
  transform: scale(0.23 , 0.23);
  overflow: hidden;
  margin-top: -6px;   
  text-align: left;
  float: left;
  margin-left: -6px;
}
.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.nav-links.active{
  color: #fff;
  font-weight: bold;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #fefaf2;
}

.nav-item .active {
  color: #fefaf2;
  border: 1px solid #fefaf2;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #3d3d3d;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #fefaf2;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fefaf2;
  }
}

