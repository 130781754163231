@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@font-face {
  font-family: "Whyte";
  src: url("https://devstaticasset.azureedge.net/font/ABCWhyte-Regular.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
}
.text {
  font-family: 'Quicksand', sans-serif;
}

.text-2 {
  font-family: "Whyte", sans-serif;
}

.text-med {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}
.bold {
  font-family: 'Bungee', monospace;
  line-height: 5.2rem;
}

.bold-fira {
  font-family: 'Fira Sans';
  font-weight: bold;
}

body {
  background-color: #b2aea6;
}
.container {
  animation-duration: 0.45s;
  animation-name: FadeInDown;
}

@keyframes FadeInDown{
  from {
    opacity: 0; 
    transform: translateY(2rem)
  }

  to {
    opacity: 1; transform: translateY(0rem)
  }
}
form {
  margin: 5rem auto 0;
  width: 24rem;
}
form div {
  width: inherit;
  margin-bottom: 1rem;
}
form label {
  display: block;
  color: #fff;
}
form input {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  width: inherit;
  height: 2rem;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
}
form input[type="submit"] {
  height: 2.5rem;
  font-size: 1rem;
  color: #fff;
  background: #0971f1;
  cursor: pointer;
}
form p {
  margin: 0;
  color: #f90;
}
.head{
  margin-top: -10rem;
}
.heading{

vertical-align: text-top;

}
.heleft{
  display:inline
}
.left{
  display:list-item;
  text-align: center;
}
.boox{
  margin-top: 1rem;
  display:flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.booxlang{
  display:flex;
  justify-content: center
  ;}
.input{
  display: flex;
  justify-content: flex-start;

}
.pages {
  color: #fefaf2;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 10%;
}
